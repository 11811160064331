<template>
  <div>
   <base-header class="pb-1">
       <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>

   <div class="container-fluid mt--6">
        <form>
            <card class="d-print-none">
                <h3 slot="header" class="mb-0">Relat�rio de Produtividade (Atendente x Plano)</h3>
                <div class="form-row mt-3">
                    <div class="col-md-6">
                        <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Inicio">
                            <flat-picker slot-scope="{focus, blur}"
                                        @on-open="focus"
                                        @on-close="blur"
                                        :config="{allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y'}"
                                        class="form-control datepicker"
                                        placeholder="Data Inicial"
                                        v-model="filter.dateBegin">
                            </flat-picker>
                        </base-input>
                    </div>   
                    <div class="col-md-6">
                        <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Final">
                            <flat-picker slot-scope="{focus, blur}"
                                        @on-open="focus"
                                        @on-close="blur"
                                        :config="{allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y'}"
                                        class="form-control datepicker"
                                        placeholder="Data Final"
                                        v-model="filter.dateEnd">
                            </flat-picker>
                        </base-input>
                    </div>
                </div>    
                <div class="col-md-12 text-right mb-3">
                    <base-button type="secundary" native-type="button" @click="printReport"><i class="fas fa-print mr-1"></i>Imprimir</base-button>
                    <base-button type="primary" native-type="button" @click="loadReport" @keypress.enter="loadReport"><i class="fas fa-filter mr-1"></i> Filtrar</base-button>
                </div>
            </card>
            
            <!-- Attendent -->    
            <div class="col-md-12">
                <h3>Atendente</h3>
                 <card>
                    <table class="table-striped font-tabela-peq">
                        <thead> 
                            <th class="text-center">Atendente</th>
                            <th class="text-center" v-for="header in reportAttendentData.header" :key="header.id" style="word-wrap: break-word !important; max-width: 100px;">
                                {{header.name}}
                            </th>  
                            <th>Total</th> 
                        </thead>    
                        <tbody class="font-tabela-peq"> 
                            <tr v-for="item in reportAttendentData.itens" :key="item.userId">
                                <td class="text-left" style="min-width: 180px;">{{item.userName}}</td>
                                <td class="text-center" v-for="horizontal in item.horizontalData" :key="horizontal.planId" :title="item.userName + ' - ' +horizontal.planName">
                                    <a href="javascript:void(0);" @click="goFilterDetail(horizontal.planId, item.userId, null)">
                                         {{horizontal.quantity}}
                                    </a>
                                </td>
                                <td class="text-center" :title="item.userName">
                                    <a href="javascript:void(0);" @click="goFilterDetail(null, item.userId, null)">
                                        <strong>{{item.horizontalDataTotal}}</strong>
                                    </a>
                                </td>
                            </tr>  
                             <tr  style="background-color: #eee;">
                                <td class="text-center"><strong>Total</strong></td>
                                <td class="text-center" v-for="(footer, index) in reportAttendentData.footer" :key="footer.id">
                                    <a href="javascript:void(0);" @click="goFilterDetail(reportAttendentData.header[index].id, null, null)">
                                        <strong> {{footer.quantity}}</strong>
                                    </a>
                                </td>
                             </tr>    
                        </tbody>    
                    </table>    
                 </card>
            </div>  

            <!-- Broker -->    
            <div class="col-md-12">
                <h3>Corretores</h3>
                 <card>
                    <table class="table-striped font-tabela-peq">
                        <thead> 
                            <th class="text-center">Corretor</th>
                            <th class="text-center" v-for="header in reportBrokerData.header" :key="header.id" style="word-wrap: break-word !important; max-width: 100px;">
                                {{header.name}}
                            </th>  
                            <th>Total</th> 
                        </thead>    
                        <tbody class="font-tabela-peq"> 
                            <tr v-for="item in reportBrokerData.itens" :key="item.userId">
                                <td class="text-left" style="min-width: 180px;">{{item.userName}}</td>
                                <td class="text-center" v-for="horizontal in item.horizontalData" :key="horizontal.planId" :title="item.userName + ' - ' +horizontal.planName">
                                   <a href="javascript:void(0);" @click="goFilterDetail(horizontal.planId, null, item.userId)">
                                       {{horizontal.quantity}}
                                   </a>
                                </td>
                                 <td class="text-center" :title="item.userName"><strong>{{item.horizontalDataTotal}}</strong></td>
                            </tr>  
                             <tr  style="background-color: #eee;">
                                <td class="text-center"><strong>Total</strong></td>
                                <td class="text-center" v-for="footer in reportBrokerData.footer" :key="footer.id">
                                   <strong> {{footer.quantity}}</strong>
                                </td>
                             </tr>    
                        </tbody>    
                    </table>    
                 </card>
            </div>  
        </form>   

         <modal :show.sync="showModalDetails">
             <table class="table-striped font-tabela-peq">
                <thead> 
                    <th class="text-center">ID</th>
                    <th class="text-center">Cliente</th>
                    <th class="text-center">Plano</th>
                    <th class="text-center">Qtd.Vidas</th>
                    <th class="text-center">Valor</th>
                </thead>    
                <tbody class="font-tabela-peq"> 
                    <tr v-for="sub in reportDetailData" :key="sub.id">
                        <td class="text-center">{{sub.id}}</td>
                        <td class="text-left">{{sub.holder.name}}</td>
                        <td class="text-left">{{sub.plan.name}}</td>
                        <td class="text-center">{{sub.quantityLifes}}</td>
                        <td class="text-left">{{sub.totalPriceFmt}}</td>
                    </tr>    
                </tbody>    
            </table>    
         </modal>    
    </div> <!--- fecha conteudo -->
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
   components: {
      flatPicker
  },
  mounted(){
      this.loadReport()
  },
  data() {
    return {
        showModalDetails: false,
        reportAttendentData : [],
        reportBrokerData : [],
        reportDetailData : [],
        filter:{
            dateBegin: null,
            dateEnd: null
        },
        filterDetail:{
            dateBegin: null,
            dateEnd: null,
            idPlan: null,
            idBroker: null,
            idUser: null
        }
    }
  },
  methods: {
      printReport(){
          window.print()
      },
      loadReport(){
        //attendent  
        this.$clubApi.post("/report/user-plan-report", this.filter)
        .then((response) => {
            this.reportAttendentData = response.data.object
        }) .catch((error) => {
           this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
        //broker
        this.$clubApi.post("/report/broker-plan-report", this.filter)
        .then((response) => {
            this.reportBrokerData = response.data.object
        }) .catch((error) => {
           this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
      },
      goFilterDetail(planId, userId, brokerId){
        //filter
        this.filterDetail.idPlan = planId
        this.filterDetail.idUser = userId
        this.filterDetail.idBroker = brokerId
        this.filterDetail.dateBegin = this.filter.dateBegin
        this.filterDetail.dateEnd = this.filter.dateEnd

        //get data
        this.$clubApi.post("/report/detail-plan-report", this.filterDetail)
        .then((response) => {
            this.reportDetailData = response.data.object
        }) .catch((error) => {
            this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
            this.showModalDetails = true
        })
      }
  }
};
</script>

<style>
.font-tabela-peq {
    font-size: 9px !important;
}
</style>